import { FormikValues } from 'formik';
import { REACT_APP_QNACLIENTFILTER, REGISTRATION_DOMAIN } from 'gatsby-env-variables';

import {
    CreateWebAccountRequest,
    RegistrationRequest,
    RegistrationResponse
} from 'state/registration/registration.types';

import { detectCardType, getCreditCardEnding, getMonthNum, getNameParts } from 'util/payments';
import { removeChars, removeLastSpace } from 'util/string';

export class RegistrationMappers {
    static continueRegistrationToMinimalFinalize(
        formProfileSetup: FormikValues,
        continueRegistrationResponse: RegistrationResponse
    ): RegistrationRequest {
        const birthDate = `${formProfileSetup.dobMonth}-${formProfileSetup.dobDay}-${formProfileSetup.dobYear}`;

        const _zipCode =
            formProfileSetup.zipCode.length > 5 ? formProfileSetup.zipCode.slice(0, 5) : formProfileSetup.zipCode;
        const _zip4 = formProfileSetup.zipCode.length > 5 ? formProfileSetup.zipCode.slice(-4) : '';
        const _areaCode = removeChars(formProfileSetup.phoneNumber).substring(0, 3);
        const _phoneNumber = removeChars(formProfileSetup.phoneNumber).substring(3);
        const _countryNumber = formProfileSetup.state === 'PR' ? 3 : 1;
        const _country = formProfileSetup.state === 'PR' ? 'PR' : 'USA';

        return {
            ...continueRegistrationResponse,
            patientRegistration: {
                ...continueRegistrationResponse?.patientRegistration,
                birthDate: birthDate,
                address1: formProfileSetup.address,
                zipCode: _zipCode,
                stateCode: formProfileSetup.state,
                city: formProfileSetup.city,
                primaryCareProvider: formProfileSetup.providerName,
                genderCode: formProfileSetup.gender,
                ethnicityId: formProfileSetup.ethnicity,
                raceId: formProfileSetup.race,
                phoneNumber: _phoneNumber,
                addressSeqNumb: -1,
                addressTypeNum: 1,
                countryNumber: _countryNumber,
                country: _country,
                zip4: _zip4,
                phoneSeqNum: -1,
                phoneType: null,
                phoneTypeNum: 1,
                areaCode: _areaCode
            },
            patientAllergies: {
                ...continueRegistrationResponse?.patientAllergies,
                other: ''
            },
            patientConditions: {
                ...continueRegistrationResponse.patientConditions,
                other: ''
            }
        };
    }

    // RegistrationFormSchema
    static createWebAccount(formProfileSetup: FormikValues, pciToken: string): CreateWebAccountRequest {
        const names = removeLastSpace(formProfileSetup.nameOnCard).split(' ');
        const { firstName, middleName, lastName } = getNameParts(names) || {};

        return {
            PlanAlias: 'BRD02',
            Payor: REACT_APP_QNACLIENTFILTER,
            Domain: REGISTRATION_DOMAIN,
            Email: formProfileSetup.email,
            UserName: formProfileSetup.email,
            Password: formProfileSetup.password,
            FirstName: formProfileSetup.firstName,
            LastName: formProfileSetup.lastName,
            IsAgeConfirmed: formProfileSetup.isAgeConfirmed,
            IsGovernmentInsuranceEnrolled: formProfileSetup.isGovernmentInsuranceEnrolled,
            MembershipAssociation: {
                PlanId: formProfileSetup.membershipPlanId,
                PromoCode: formProfileSetup.promoCode,
                IsAnotheBillingAddress: false,
                IsPaymentFSAorHSA: formProfileSetup.fsaCard,
                IsAuthorizePayment: formProfileSetup.isAuthorizePayment,
                TypePlan: formProfileSetup.typePlan === 'Monthly' ? 0 : 1,
                AnotheAddress: false,
                PaymentCard: {
                    pciToken: pciToken,
                    fsaCard: formProfileSetup.fsaCard,
                    cardActive: true,
                    cardFirstName: firstName,
                    cardLastName: lastName,
                    cardMiddleName: middleName,
                    defaultCard: true,
                    epostPatientNum: null,
                    forceApproval: true,
                    cardYear: formProfileSetup.expYear,
                    cardMonthNum: Number(getMonthNum(formProfileSetup.expMonth)),
                    cardMonthDesc: formProfileSetup.expMonth,
                    cardNumber: `T${formProfileSetup.cardNumber}`,
                    cardSeqNum: '-1',
                    secureCardNumber: `${getCreditCardEnding(formProfileSetup.cardNumber, '**************')}`,
                    cardTypeNum: `${detectCardType(formProfileSetup.cardNumber)?.index}`,
                    cardType: `${detectCardType(formProfileSetup.cardNumber)?.key}`,
                    cardExpiration: `${formProfileSetup.expMonth}/${formProfileSetup.expYear}`,
                    cardName: `${firstName} ${lastName}`,
                    cardIsExpired: false,
                    messageErrorText: '',
                    messageStatus: true,
                    messageText: '',
                    spendingLimit: '',
                    securityCode: ''
                }
            },
            messageErrorText: '',
            messageStatus: true,
            messageText: ''
        };
    }
}
